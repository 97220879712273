import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Linkedin } from 'react-feather';

import Form from 'react-bootstrap/Form';

import { routes, CustomRouteObject } from "../../AppRoutes";

import NavImage from "../../assets/img/flowly-logo.png";
import { enFlag, frFlag, spFlag } from "../../assets/img/countries";

const langMap = new Map();
langMap.set("fr", frFlag);
langMap.set("en", enFlag);
langMap.set("sp", spFlag)

const handleClose = () => {
    const closeButton = document.querySelector('.btn-close') as HTMLButtonElement | null;
    if (closeButton) closeButton.click();
};

const NavMenu = (): React.JSX.Element => {
    const location = useLocation();
    const path = location.pathname;
    const { t, i18n } = useTranslation();
    const [currentLang, setCurrentLang] = React.useState("fr");
    const [langIcon, setLangIcon] = React.useState(langMap.get("fr"));
    const expand = "lg";
    const langs = Array.from(langMap.keys());
    let loops = 0;
    let mlAuto = '';

    React.useEffect(() => {
        setCurrentLang(localStorage.getItem("lang") == undefined ? navigator.language : localStorage.getItem("lang")!);
    }, [path]);

    React.useEffect(() => {
        setLangIcon(langMap.get(currentLang));
        i18n.changeLanguage(currentLang);
        localStorage.setItem("lang", currentLang);
    }, [currentLang]);

    return (
            <>
            <Navbar expand={expand} className="mb-3 flowly-navbar">
                <Container>

                    <Navbar.Brand as={NavLink} to="/accueil" end>
                        <img src={NavImage} alt="Logo de Flowly" />
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-${expand}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} className="flowly-logo-offcanvas">
                                <Navbar.Brand as={NavLink} to="/accueil" onClick={handleClose} end>
                                    <img className="nav-home" src={NavImage} alt="Logo de Flowly" />
                                </Navbar.Brand>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1 pe-3 navbar-content">
                                {
                                    routes[0].children !== undefined && routes[0].children.map((el: CustomRouteObject, index: number) => {
                                        mlAuto = '';
                                        if (loops <= 0) mlAuto = "d-none";
                                        if (loops == 1) mlAuto = "nav-ml-auto";
                                        ++loops;
                                        if (el.visible !== undefined && !el.visible) return;
                                        let elTitle = el.title === undefined ? "" : t(el.title);
                                        let tempPath = "";
                                        if (el.path !== undefined) tempPath = el.path;
                                        if (el.children === undefined)
                                            return (<Nav.Link className={`default-nav-padding ${mlAuto}`} key={index} as={NavLink} eventKey={tempPath} to={tempPath} onClick={handleClose} active={el.path === undefined ? false : path.startsWith(el.path)} end>
                                                {elTitle}
                                                </Nav.Link>)
                                        else
                                            return (<NavDropdown className={`default-nav-padding ${mlAuto}`} key={index} title={elTitle} id="basic-nav-dropdown" onClick={handleClose} active={el.path === undefined ? false : path.startsWith(el.path)}>
                                                {
                                                    el.children.map((child, indexChild) => {
                                                        elTitle = child.title === undefined ? "" : t(child.title);
                                                        if (child.path !== undefined) tempPath = `${el.path}/${child.path}`;
                                                        return <NavDropdown.Item key={indexChild} className='text-style' as={NavLink} eventKey={tempPath} to={tempPath} onClick={child.close ? handleClose : undefined}>{elTitle}</NavDropdown.Item>
                                                    })
                                                }
                                                </NavDropdown>)
                                    })
                                }

                                <Link to="/contact/?link=demo" className="flowly-btn red nav-demo">{t('NavBar.Demo')}</Link>    
                                <div className="navbar-flowly-icon">
                                    <div className="lk-container">
                                        <a href="https://www.linkedin.com/company/flowly1/" target="_blank" className="navlink-outside"><Linkedin size="18" /></a>
                                        <p dangerouslySetInnerHTML={
                                            { __html: t('NavBar.FollowFlowly') === undefined ? "" : t('NavBar.FollowFlowly') }
                                        } />
                                    </div>
                                    <Link to="/contact/?link=demo" className="flowly-btn red out-demo">{t('NavBar.Demo')}</Link>
                                    <NavDropdown className="navbar-language" title={<div className='lc-text'><img className="flowly-country-flag round-border d-none" src={langIcon} /> {t(`Langue.tag`)}</div>}>
                                        { 
                                            langs.map((lang, index)=>
                                            <NavDropdown.Item id={lang} eventKey={lang} key={index} onClick={(e)=>{
                                                setCurrentLang(e.currentTarget.id);
                                            }}>
                                                <img className="flowly-country-flag round-border d-none" src={langMap.get(lang)} /> <span style={{marginLeft: "10px"}}>{t(`Langue.${lang}`)}</span>
                                            </NavDropdown.Item>)
                                        }
                                    </NavDropdown>
                                </div>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
    </>
    );
};

export default NavMenu;

import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';


import Section from '../shared/Section';
import { ExpertiseObject, ExpertiseData } from './types/Expertises';
import Carroussels from '../shared/carroussel/Carroussels';

import { useState } from 'react';

import { ArrowUpRight, ArrowDown, ArrowDownRight } from 'react-feather';
import { Rouen, Avignon, Reunion, Valence, Douai, Carjaune } from '../../assets/img/experiences';
import { SupplyDemand_img, TAD_img, OD_img, Bike_img, Correspondence_img, Evolve_img, Fraud_img, Information_img, Prediction_img, Punctuality_img,Traffic_img, HeroBg } from '../../../src/assets/img/expertises';

import './expertises.css';
import { useSearchParams } from 'react-router-dom';

interface ElementData {
    altText: string,
    caption: string,
    description?: string,
    key: number,
    link: string,
    author?: string,
    icon?: string
};

const expertises: ExpertiseObject = {
    "Pages.Expertises.SupplyDemand.Title" : {
        title: 'Pages.Expertises.SupplyDemand.Title',
        catchPhrase: 'Pages.Expertises.SupplyDemand.Catch',
        desc: 'Pages.Expertises.SupplyDemand.Description',
        imgSrc: SupplyDemand_img, 
        active: true
    },
    "Pages.Expertises.OD.Title" : {
        title: 'Pages.Expertises.OD.Title',
        catchPhrase: 'Pages.Expertises.OD.Catch',
        desc: 'Pages.Expertises.OD.Description',
        imgSrc: OD_img,
        active: false
    },
    "Pages.Expertises.Prediction.Title" : {
        title: 'Pages.Expertises.Prediction.Title',
        catchPhrase: 'Pages.Expertises.Prediction.Catch',
        desc: 'Pages.Expertises.Prediction.Description',
        imgSrc: Prediction_img,
        active: false
    },
    "Pages.Expertises.Correspondence.Title" : {
        title: 'Pages.Expertises.Correspondence.Title',
        catchPhrase: 'Pages.Expertises.Correspondence.Catch',
        desc: 'Pages.Expertises.Correspondence.Description',
        imgSrc: Correspondence_img,
        active: false
    },
    "Pages.Expertises.Evolve.Title" : {
        title: 'Pages.Expertises.Evolve.Title',
        catchPhrase: 'Pages.Expertises.Evolve.Catch',
        desc: 'Pages.Expertises.Evolve.Description',
        imgSrc: Evolve_img,
        active: false
    },
    "Pages.Expertises.Fraud.Title" : {
        title: 'Pages.Expertises.Fraud.Title',
        catchPhrase: 'Pages.Expertises.Fraud.Catch',
        desc: 'Pages.Expertises.Fraud.Description',
        imgSrc: Fraud_img,
        active: false
    },
    "Pages.Expertises.Punctuality.Title" : {
        title: 'Pages.Expertises.Punctuality.Title',
        catchPhrase: 'Pages.Expertises.Punctuality.Catch',
        desc: 'Pages.Expertises.Punctuality.Description',
        imgSrc: Punctuality_img,
        active: false
    },
    "Pages.Expertises.Traffic.Title" : {
        title: 'Pages.Expertises.Traffic.Title',
        catchPhrase: 'Pages.Expertises.Traffic.Catch',
        desc: 'Pages.Expertises.Traffic.Description',
        imgSrc: Traffic_img,
        active: false
    },
    "Pages.Expertises.Bike.Title" : {
        title: 'Pages.Expertises.Bike.Title',
        catchPhrase: 'Pages.Expertises.Bike.Catch',
        desc: 'Pages.Expertises.Bike.Description',
        imgSrc: Bike_img,
        active: false
    },
    "Pages.Expertises.TAD.Title" : {
        title: 'Pages.Expertises.TAD.Title',
        catchPhrase: 'Pages.Expertises.TAD.Catch',
        desc: 'Pages.Expertises.TAD.Description',
        imgSrc: TAD_img,
        active: false
    },
    "Pages.Expertises.Information.Title" : {
        title: 'Pages.Expertises.Information.Title',
        catchPhrase: 'Pages.Expertises.Information.Catch',
        desc: 'Pages.Expertises.Information.Description',
        imgSrc: Information_img,
        active: false
    }

};

const Expertises = (): React.JSX.Element => {
    const [selectedExpertise, setSelectedExpertise] = React.useState<ExpertiseData>(expertises["Pages.Expertises.SupplyDemand.Title"]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = React.useState(false);
    const { t } = useTranslation();
    const [data, setData] = React.useState<ElementData[]>([]);
    const [show, setShow] = useState(false);

    function ArrowDownFunction() {

        return (
            <a className="flowly-btn" onClick={() => setShow(currentShow => !currentShow)}
                > {t('Pages.Experiences.Discover')}
                <ArrowDownRight/>
            </a >
        );
    }
    function ArrowUpFunction() {

        return (
            <a className='flowly-btn' onClick={() => setShow(currentShow => !currentShow)}
                >{t('Pages.Experiences.Reduce')}
                <ArrowUpRight />
            </a>
        );
    }
    function REcup() {
        return (
            <a className='orange-button'>
                <span onClick={() => setShow(currentShow => !currentShow)}>{t('Pages.Experiences.Discover')}</span>
                <ArrowDownRight className='icon' onClick={() => setShow(currentShow => !currentShow)} />
            </a>
        );
    }
    function AllClientsFunction() {
        
        return (
            <div id='AllClients'>
                <Row>
                    <Col xs={12} lg={6} className='experience-card p-0'>
                        <img src={Douai} />
                    </Col>
                    <Col xs={12} lg={6} className='experience-card blue p-3'>
                        <a href='expertises/douai'>
                            <span>{t('Pages.Experiences.Discover')}</span>
                            <ArrowUpRight />
                        </a>
                        <div className='content'>
                            <span>{t('Pages.Experiences.DouaiTitle')}</span>
                            <h4>{t('Pages.Experiences.DouaiSubTitle')}</h4>
                            <p>{t('Pages.Experiences.DouaiDescription')}</p>
                        </div>
                    </Col>

                    <Col xs={12} lg={6} className='experience-card orange p-3'>
                        <a href='expertises/carjaune'>
                            <span>{t('Pages.Experiences.Discover')}</span>
                            <ArrowUpRight />
                        </a>
                        <div className='content'>
                            <span>{t('Pages.Experiences.CarjauneTitle')}</span>
                            <h4>{t('Pages.Experiences.CarjauneSubTitle')}</h4>
                            <p>{t('Pages.Experiences.CarjauneDescription')}</p>
                        </div>
                    </Col>
                    <Col xs={12} lg={6} className='experience-card d-none d-lg-block p-0'>
                        <img src={Carjaune} />
                    </Col>

                    <Col xs={12} lg={6} className='experience-card p-0'>
                        <img src={Rouen} />
                    </Col>
                    <Col xs={12} lg={6} className='experience-card blue p-3'>
                        <a href='expertises/rouen'>
                            <span>{t('Pages.Experiences.Discover')}</span>
                            <ArrowUpRight />
                        </a>
                        <div className='content'>
                            <span>{t('Pages.Experiences.RouenTitle')}</span>
                            <h4>{t('Pages.Experiences.RouenSubTitle')}</h4>
                            <p>{t('Pages.Experiences.RouenDescription')}</p>
                        </div>
                    </Col>

                    <Col xs={12} lg={6} className='experience-card orange p-3'>
                        <a href='expertises/valence'>
                            <span>{t('Pages.Experiences.Discover')}</span>
                            <ArrowUpRight />
                        </a>
                        <div className='content'>
                            <span>{t('Pages.Experiences.ValenceTitle')}</span>
                            <h4>{t('Pages.Experiences.ValenceSubTitle')}</h4>
                            <p>{t('Pages.Experiences.ValenceDescription')}</p>
                        </div>
                    </Col>
                    <Col xs={12} lg={6} className='experience-card d-none d-lg-block p-0'>
                        <img src={Valence} />
                    </Col>
                    <Col xs={12} lg={6} className='experience-card p-0'>
                        <img src={Avignon} width='400px' border-radius='Opx' />
                    </Col>
                    <Col xs={12} lg={6} className='experience-card caches blue p-3'>
                        <a href='expertises/avignon'>
                            <span>{t('Pages.Experiences.Discover')}</span>
                            <ArrowUpRight />
                        </a>
                        <div className='content'>
                            <span>{t('Pages.Experiences.AvignonTitle')}</span>
                            <h4>{t('Pages.Experiences.AvignonSubTitle')}</h4>
                            <p>{t('Pages.Experiences.AvignonDescription')}</p>
                        </div>
                    </Col>

                    <Col xs={12} lg={6} className='experience-card orange last p-3'>
                        <a href='expertises/reunion'>
                            <span>{t('Pages.Experiences.Discover')}</span>
                            <ArrowUpRight />
                        </a>
                        <div className='content'>
                            <span>{t('Pages.Experiences.ReunionTitle')}</span>
                            <h4>{t('Pages.Experiences.ReunionSubTitle')}</h4>
                            <p>{t('Pages.Experiences.ReunionDescription')}</p>
                        </div>
                    </Col>
                    <Col xs={12} lg={6} className='experience-card d-none d-lg-block p-0'>
                        <img src={Reunion} />
                    </Col>
                </Row>
            </div>
        );
    }


    function ShortClientsFunction() {

        return (
            <div id='ClientsVisibles'>
                <Row>
                    <Col xs={12} lg={6} className='experience-card p-0'>
                        <img src={Douai} />
                    </Col>
                    <Col xs={12} lg={6} className='experience-card blue p-3'>
                        <a href='expertises/douai'>
                            <span>{t('Pages.Experiences.Discover')}</span>
                            <ArrowUpRight />
                        </a>
                        <div className='content'>
                            <span>{t('Pages.Experiences.DouaiTitle')}</span>
                            <h4>{t('Pages.Experiences.DouaiSubTitle')}</h4>
                            <p>{t('Pages.Experiences.DouaiDescription')}</p>
                        </div>
                    </Col>

                    <Col xs={12} lg={6} className='experience-card orange p-3'>
                        <a href='expertises/carjaune'>
                            <span>{t('Pages.Experiences.Discover')}</span>
                            <ArrowUpRight />
                        </a>
                        <div className='content'>
                            <span>{t('Pages.Experiences.CarjauneTitle')}</span>
                            <h4>{t('Pages.Experiences.CarjauneSubTitle')}</h4>
                            <p>{t('Pages.Experiences.CarjauneDescription')}</p>
                        </div>
                    </Col>
                    <Col xs={12} lg={6} className='experience-card d-none d-lg-block p-0'>
                        <img src={Carjaune} />
                    </Col>

                    <Col xs={12} lg={6} className='experience-card p-0'>
                        <img src={Rouen} />
                    </Col>
                    <Col xs={12} lg={6} className='experience-card blue p-3'>
                        <a href='expertises/rouen'>
                            <span>{t('Pages.Experiences.Discover')}</span>
                            <ArrowUpRight />
                        </a>
                        <div className='content'>
                            <span>{t('Pages.Experiences.RouenTitle')}</span>
                            <h4>{t('Pages.Experiences.RouenSubTitle')}</h4>
                            <p>{t('Pages.Experiences.RouenDescription')}</p>
                        </div>
                    </Col>

                    <Col xs={12} lg={6} className='experience-card orange p-3'>
                        <a href='expertises/valence'>
                            <span>{t('Pages.Experiences.Discover')}</span>
                            <ArrowUpRight />
                        </a>
                        <div className='content'>
                            <span>{t('Pages.Experiences.ValenceTitle')}</span>
                            <h4>{t('Pages.Experiences.ValenceSubTitle')}</h4>
                            <p>{t('Pages.Experiences.ValenceDescription')}</p>
                        </div>
                    </Col>
                    <Col xs={12} lg={6} className='experience-card d-none d-lg-block p-0'>
                        <img src={Valence} />
                    </Col>
                </Row>
            </div>
        );
    }

    const selectExpertise = async (key: string)=> {
        try {
            Object.values(expertises).map((expertise)=>{expertise.active = false});
            expertises[key].active = true;
            setSelectedExpertise(expertises[key]);
        } catch(e){}
    };

    React.useEffect(()=>{
        if (searchParams != undefined) {
            const id = searchParams.get("id");
            if (id != undefined) selectExpertise(id);
        }
    }, []);
    React.useEffect(() => {
        function changeStyle() {
            document.querySelector('.Display-block') ;
        }
    });
    //function changeStyle(): React.MouseEventHandler<SVGElement> | undefined {
    //    document.querySelector('.Display-block');
    //};

    React.useEffect(()=>{
        if (searchParams == undefined || searchParams.size == 0) return;
        var accordion: HTMLElement = document.querySelector('.expertise-wrapper') as HTMLElement;
        var menu = accordion.querySelector('.menu');
        var accordionDisplay = getComputedStyle(accordion).display;
        if (accordion != undefined && accordionDisplay == "none") {
            accordion = document.querySelector('.expertise-wrapper-mobile') as HTMLElement;
            menu = null;
        }
        if (menu) {
            var rect = accordion?.getBoundingClientRect();
            if (rect != undefined) window.scrollBy(0, rect.top - 80);
            const activeElement = document.querySelector('.expertise-wrapper .menu .element.active') as HTMLElement;
            if (activeElement != undefined) {
                var childRect = activeElement.getBoundingClientRect();
                menu.scrollBy(0, childRect.bottom - rect.bottom);
            }
        } else {
            const activeElement = document.querySelector('.expertise-wrapper-mobile .element.active');
            if (activeElement != undefined) {
                rect = activeElement.getBoundingClientRect();
                window.scrollBy(0, rect.top - 80);
            }
        }
    }, [selectedExpertise]);


    return (
        <>

            <Section className="hero-section py-5">
                <div className="text-start title ">
                        <h2 dangerouslySetInnerHTML={
                            { __html: t('Pages.Expertises.Title') === undefined ? "" : t('Pages.Expertises.Title') }
                        } />
                    <p dangerouslySetInnerHTML={{ __html: t('Pages.Expertises.SubTitle') }}></p>
                    <img src={HeroBg} className='hero-img-mobile' />
                </div>
            </Section>
            <img src={HeroBg} className='hero-img' />

            <Section className='' style={{ height: "fit-content" }} dataTrackContent={true} dataContentName='expertises'>
                <Row className='text-center py-4'>
                    <Col xs={12}>
                    <h4 dangerouslySetInnerHTML={
                            { __html: t('Pages.Expertises.FlowlyExpertise') === undefined ? "" : t('Pages.Expertises.FlowlyExpertise') }
                        } />
                    </Col>
                </Row>
                <Row>
                    <div className='expertise-wrapper'>
                        <div className='menu'>
                            {
                                Object.values(expertises).map((expertise, index)=>{
                                    return <div key={index} className={`element ${expertise.active ? 'active' : ''}`} onClick={(e)=>{
                                        Object.values(expertises).map((expertise)=>{expertise.active = false});
                                        expertise.active = true;
                                        expertise.htmlNode = e.currentTarget;
                                        setSelectedExpertise(expertise);
                                    }}>
                                        <div>{expertise?.title === undefined ? "" : t(expertise.title)}
                                            <ArrowUpRight className='icon' />
                                            {
                                                selectedExpertise !== undefined && (
                                                    <>
                                                        <div className='soustexte' dangerouslySetInnerHTML={
                                                            { __html: expertise.active ? selectedExpertise?.catchPhrase === undefined ? "" : t(selectedExpertise?.catchPhrase) : "" }
                                                        } />
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div className='content'>
                            {
                                selectedExpertise !== undefined && (
                                    <>
                                        <img src={selectedExpertise?.imgSrc} />
                                        <p dangerouslySetInnerHTML={
                                            { __html: selectedExpertise?.desc === undefined ? "" : t(selectedExpertise?.desc) }
                                        } />

                                    </>
                                )
                            }
                        </div>
                    </div>
                    <div className='expertise-wrapper-mobile'>
                        {
                            Object.values(expertises).map((expertise, index)=>{
                                return (
                                <div className='menu' key={index}>
                                    <div className={`element ${expertise.active ? 'active' : ''}`} onClick={(e)=>{
                                        const menuHeight: number = e.currentTarget.getBoundingClientRect().height;
                                        setTimeout(()=>{
                                            const rect = document.querySelector('.content.active')?.getBoundingClientRect();
                                            if (rect != undefined) window.scrollBy(0, rect.top - (menuHeight + 80));
                                        }, 1);
                                        Object.values(expertises).map((expertise)=>{expertise.active = false});
                                        expertise.active = true;
                                        expertise.htmlNode = e.currentTarget;
                                            setSelectedExpertise(expertise);
                                    }}>
                                        <p>{expertise?.title === undefined ? "" : t(expertise.title)}</p>
                                        <ArrowDown className='icon' />
                                    </div>
                                    <div className={`content ${expertise.active ? 'active' : ''}`} >
                                        {
                                           expertise.active && 
                                            <>
                                                <img src={selectedExpertise?.imgSrc} />
                                                <p dangerouslySetInnerHTML={
                                                        { __html: selectedExpertise?.desc === undefined ? "" : t(selectedExpertise?.desc) }
                                                    } />
                                            </>
                                        }
                                    </div>
                                </div>)
                            })
                        }
                    </div>
                </Row>
            </Section>

            <Section approaches-container>
                <Row className='justify-content-center text-center'>
                    <Col xs={12} className="pt-4">
                        <h4 dangerouslySetInnerHTML={
                            { __html: t('Pages.Expertises.Approach.Title') === undefined ? "" : t('Pages.Expertises.Approach.Title') }
                        } />
                    </Col>
                    <Col xs={12} lg={6}>
                        <p dangerouslySetInnerHTML={
                            { __html: t('Pages.Expertises.Approach.Description') === undefined ? "" : t('Pages.Expertises.Approach.Description') }
                        } />
                    </Col>
                    <Col xs={12} className="text-center">
                        <Carroussels />
                    </Col>
                </Row>
            </Section>



            <Section className='orange-section' dataTrackContent={true} dataContentName='expertise_approaches'>
                <Row className='justify-content-center text-center py-4'>
                    <Col xs={12}>
                        <h4 className='text-white'>{t('Pages.Experiences.Menu')}</h4>
                    </Col> 
                </Row>
            </Section>

            <Section className='experience-section pb-5' dataTrackContent={true} dataContentName='expertise_clients'>
                <Row className='justify-content-center '>
                    <Col md={12} lg={8} >
                        {show ? <AllClientsFunction /> : null}
                        {!show ? <ShortClientsFunction /> : null}
                        <div className='clientsdiscover'>
                            {show ? <ArrowUpFunction /> : null}
                            {!show ? <ArrowDownFunction /> : null}
                        </div>
                    </Col>
                </Row>
            </Section>
        </>


    );
};

export default Expertises;

